:root {
    --shadowblue: #518EA6;
    --lightblue: #8BC5DB;
}

* {
    font-family: 'poppinsmedium', sans-serif;
}

body {
    background-color: white;

}

h1 {
    color: midnightblue;
}

.nav-link {
    font-weight: bold;
    color: white;
}

.img-pipa {
    height: 100vh;
    object-fit: cover;
    display: block;
    z-index: 0;
}

#home,
#ras,
#mdba,
#pipa,
#galerij,
#contact {
    display: none;
}

#ras:target,
#mdba:target,
#pipa:target,
#galerij:target,
#contact:target {
    display: flex;
}

#home:target {
    display: block;
}

ul {
    list-style: none;
    padding: 0;
}

h1, h2, h3 {
    color: var(--shadowblue);
}

li a, li {
    color: var(--lightblue);
    text-decoration: none;
    font-weight: bold;
}

li:hover, a:hover, li a:hover {
    color: var(--shadowblue);
}

nav {
    position: fixed;
}



/*.home-banner {*/
/*    background-image: url("../assets/images/pipa_soft_velvet.jpeg");*/
/*    height: 100vh;*/
/*    background-attachment: fixed;*/
/*    background-position: center;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    margin-bottom: -25vh;*/
/*    !*display: none;*!*/
/*}*/

body {
    background-image: url("../assets/images/pipa_soft_velvet.jpeg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav {
    background-color: var(--lightblue);
}

section {
    min-height: 70vh;
    background-color: white;
}

img {
    width: 100%;
}

footer {
    background-color: white;
}

.text-link {
    color: var(--shadowblue);
}


#nieuws {
    min-height: 0;
    /*background-color: var(--lightblue);*/
    /*border: 2px solid var(--lightblue);*/
    /*color: white;*/
}

.nav-link {
    position: relative;
    display: block;
    color: white;
}

.nav-link:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--shadowblue);
    transition: 0.3s;
}

.nav-link:hover {
    color: white;
}

.nav-link:hover:after, .active:after {
    width: 100%;
}

.navbar-div {
    background-color: var(--lightblue);
}

.navbar-brand {
    color: white;
}

.navbar-brand:hover {
    color: white;
}

.navbar-toggler-icon {
    /*color: red;*/
    /*background-color: red;*/
    color: red;
    border-color: transparent;
}

#ras,
#mdba,
#pipa,
#galerij,
#contact {
    padding-top: 100px !important;
}

#home {
    /*padding-top: 55vh;*/
    width: 100%;
}

footer {
    background-color: transparent;
}

.cover-pipa {
    padding-top: 80px;
}

p {
    color: #808080;
}

.main {
    /*background-color: white;*/
}
/*// X-Small devices (portrait phones, less than 576px)*/
/*// No media query for `xs` since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    #ras,
    #mdba,
    #pipa,
    #galerij,
    #contact {
        padding-top: 100px !important;
    }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    #ras,
    #mdba,
    #pipa,
    #galerij
    #contact {
        padding-top: 120px !important;
    }

    .cover-pipa {
        display: none;
    }

    #home {
        padding-top: 55vh;
    }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
}

@font-face {
    font-family: 'poppinsmedium_italic';
    src: url('../assets/fonts/poppins/poppins-mediumitalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/poppins/poppins-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'poppinsregular';
    src: url('../assets/fonts/poppins/poppins-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/poppins/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'poppinssemibold';
    src: url('../assets/fonts/poppins/poppins-semibold-webfont.woff2') format('woff2'),
    url('../assets/fonts/poppins/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'poppinssemibold_italic';
    src: url('../assets/fonts/poppins/poppins-semibolditalic-webfont.woff2') format('woff2'),
    url('../assets/fonts/poppins/poppins-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'poppinsmedium';
    src: url('../assets/fonts/poppins/poppins-medium-webfont.woff2') format('woff2'),
    url('../assets/fonts/poppins/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




